import React from "react";
import styled from "styled-components";
import Imgix from "react-imgix";
import { imgix } from "../data/data";
import Wrapper from "./Layout/Wrapper";
import CenteredFourSixthsGrid from "./Layout/Grids/CenteredFourSixthsGrid";
import { breakpoints } from "../styles/variables";

const ImageElem = styled.div`
  img {
    width: 75%;
    margin: -50px auto 0;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    margin-top: -30px;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: -40px;
  }
`;

const UeberUnsTextImage = ({ image }) => (
  <ImageElem>
    <Wrapper>
      <CenteredFourSixthsGrid>
        <Imgix
          src={image.url}
          imgixParams={imgix.maxWidthImage}
          htmlAttributes={{ title: image.title }}
        />
      </CenteredFourSixthsGrid>
    </Wrapper>
  </ImageElem>
);

export default UeberUnsTextImage;
